import React, { ReactElement, ReactNode } from "react";
import { Tooltip as MondayTooltip } from "monday-ui-react-core";



type ITooltipProps = {
  children: ReactElement;
  content: string;
  showArrow?: boolean;
}

function Tooltip({
  children,
  content,
  showArrow = true
}: ITooltipProps) {
  return (
    <MondayTooltip
      content={content}
      tip={showArrow}
      // modifiers={[
      //   {
      //     name: 'preventOverflow',
      //     options: {
      //       mainAxis: false
      //     }
      //   },
      //   {
      //     name: 'flip',
      //     options: {
      //       fallbackPlacements: []
      //     }
      //   }
      // ]}
      // shouldShowOnMount
      withMaxWidth
    >
      {children}
    </ MondayTooltip>
  )
}

export default Tooltip;
