import React from "react";
import { Avatar as MondayAvatar, Text } from "monday-ui-react-core";

import Avatar from "../../../components/Avatar";
import Button from "../../../components/Button";
import useGoogleConnection from "../../../hooks/services/googleHook";
import useUser from "../../../hooks/states/userHook";



function AccountSwitch() {
  const {userLogin, userSignOut, isLoading} = useGoogleConnection();
  const { GoogleDetails } = useUser();

  
  return (
    <div className="min-w-64 divide-y space-y-1">
      <div className="flex items-center gap-3">
        <Avatar
          label={GoogleDetails?.name?.fullName}
          initials={GoogleDetails?.initials}
          className="text-white"
          size={MondayAvatar.sizes.MEDIUM}
          src={GoogleDetails?.profile}
        />

        <div>
          <Text className="font-semibold">{GoogleDetails?.name?.fullName}</Text>
          <Text className="font-light text-[10px]">{GoogleDetails?.email}</Text>
        </div>
      </div>

      <div className="pt-2 flex justify-between">
        <Button 
          variant="outlined" 
          onClick={userLogin} 
          loading={isLoading}
          disabled={isLoading}
        >
          Switch Account
        </Button>

        <Button onClick={userSignOut}>Sign out</Button>
      </div>
    </div>
  )
}

export default AccountSwitch