import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import useUser from "../hooks/states/userHook";
import * as paths from "../routes/paths";


function AuthLayout() {
  const {isAuthorized} = useUser();
  const navigate = useNavigate();


  useEffect(() => {
    if(isAuthorized) {
      navigate(paths.upload, {replace: true});
    }
  }, [isAuthorized]);
  

  return (
    <div className="w-dvw h-dvh flex justify-center items-center bg-white">
      <Outlet />
    </div>
  )
}

export default AuthLayout;
