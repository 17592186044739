import { useDispatch, useSelector } from "react-redux";

import { getUser, SET_ACTIVE_BOARD, SET_USER_ACCOUNT } from "../../states/slices/userSlice";
import Logger from "../../utilities/Logger";
import MondayService from "../../services/MondayService";
import { AppFeatureBoardViewContext } from "monday-sdk-js/types/client-context.type";


const mondayService = new MondayService();

function useUser() {
  const { 
    userId, 
    accountId, 
    google, 
    boardId, 
    viewId, 
    workspaceId,
    appFeature
  } = useSelector(getUser);
  const dispatch = useDispatch();
  const isAuthorized = !!userId && !!accountId;
  const isGoogleConnected = !!google && !!google.token
  const GoogleDetails = {
    ...google,
    initials: google?.name.firstName.toUpperCase() || "" + google?.name.lastName.toUpperCase() || ""
  };
  const driveToken = google?.token;


  const updateUserContextDetails = async (context?: AppFeatureBoardViewContext, setUser = false) =>{
    try {
      let innerContext = context;
      if(!innerContext) {
        innerContext = await mondayService
          .getContext<{boardId: string}>() as AppFeatureBoardViewContext;
      }

      if( setUser) {
        dispatch(SET_USER_ACCOUNT({
          accountId: innerContext.account.id,
          userId: innerContext.user.id
        }));
      }

      dispatch(SET_ACTIVE_BOARD({
        boardId: `${innerContext.boardId}`,
        workspaceId: innerContext.workspaceId,
        viewId: innerContext.boardViewId,
        appFeature: (innerContext as any)?.appFeature.type
      }));

    } catch (error) {
      Logger.error(error);
    }
  }

  
  return {
    userId,
    accountId,
    boardId,
    viewId,
    workspaceId,
    GoogleDetails,
    driveToken,
    isAuthorized,
    appFeature,
    isGoogleConnected,
    updateUserContextDetails,
  }
}

export default useUser;
