import axios, { AxiosError } from "axios";

import { API_BASE_URL } from "../configs";
import { IRequestErrorResponse } from "../resources/types";



const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 1000000,
  // withCredentials: true
});


// Add interceptors to instance
api.interceptors.response.use((response) => {
  return response;
}, (error: AxiosError) => {
  const errorStruct: IRequestErrorResponse = {
    message: "Error",
    status: 500
  };

  // The request was made but no response was received
  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  // http.ClientRequest in node.js
  if (error.response) {
    const errorResponse = error.response;
    errorStruct.status = errorResponse.status;
    const data: Record<any, any> = <Object>errorResponse.data;

    switch (errorResponse.status) {
      case 400: // Timeout
        errorStruct.message = "Error";
        break;
      case 403:
      case 401: // Unauthorized || forbidden
        errorStruct.message = "Unauthorized";
        errorStruct.description = data?.message ?? "You are unauthorized to perform this operation.";
        errorStruct.data = {status: data?.status};
        break;
      case 408: // Bad Request
        errorStruct.message = "Connection Timeout";
        errorStruct.description = "Connection timeout. Try again";
        break;
      case 500: // Server Error
        errorStruct.message = "Request Error";
        errorStruct.description = "An error occurred serving request. Try again later.";
        break;
      default: // System Error
        errorStruct.message = "System Error"
        break;
    }

    if (!errorStruct.description) errorStruct.description = data?.message;


    return Promise.reject(errorStruct);
  } else {
    // Something happened in setting up the request that triggered an Error
    throw error;
  }
});

api.interceptors.request.use((request) => {
  // const token = getCookie(TOKEN_KEYS.token);

  // if(token) {
  //   request.headers.Authorization = token;
  // }

  return request;
});

export default api;
