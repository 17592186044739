import React from "react";
import { Loader, Text } from "monday-ui-react-core";

import Button from "../../../components/Button";
import { CircledInfoIcon } from "../../../resources/icons";
import RefreshIcon from "../../../resources/icons/RefreshIcon";
import { format, formatDistance } from "date-fns";



type IFooterProps = {
  isDriveConnected?: boolean;
  isSyncing?: boolean;
  lastUpdate?: Date;
  onRefresh?: () => void;
}

function Footer({
  isDriveConnected,
  isSyncing,
  lastUpdate,
  onRefresh,
}: IFooterProps) {


  return (
    <div className="flex w-full py-2 ps-[38px] pe-[30px] items-center border border-solid border-grayed-light">
      <div className="flex items-center w-full justify-between">
        <div className="flex gap-x-3 items-center">
          <Button
            startIcon={isSyncing? undefined : RefreshIcon}
            disabled={!isDriveConnected || isSyncing}
            onClick={onRefresh}
            className={isSyncing ? "gap-2" : ""}
          >
            {isSyncing && (<Loader size={Loader.sizes.XS} />)}
            {isSyncing? "  Syncing" : "Refresh"}
          </Button>

            <Text className="text-grayed text-xs flex items-center gap-2">
              <CircledInfoIcon className="text-grayed h-4 w-4" />
              Published by Google Sheets - Refreshed automatically every minute
            </Text>

            <Text className="text-grayed text-[9px] inline-flex items-center gap-2">
              {lastUpdate && (
                <span className="font-semibold">
                  last updated:&nbsp;
                  {
                    formatDistance(
                      lastUpdate,
                      new Date(),
                      { includeSeconds: true }
                    )
                  }
                </span>
              )}
            </Text>          
        </div>
      </div>
    </div>
  )
}

export default Footer