import React from "react";



type Props = {
  className?: string;
}

function RefreshIcon({
  className = "w-5 h-5"
}: Props) {
  return (
    <svg 
      width="13" 
      height="16" 
      viewBox="0 0 13 16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      className={className}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6.24491 1.58741C6.41306 1.64313 6.5227 1.7744 6.5227 1.92001V3.13092H6.72725C8.55637 3.13092 9.66792 3.8778 10.3832 4.8077C10.9385 5.52961 11.2744 6.39151 11.5464 7.08971C11.6126 7.25969 11.6751 7.41997 11.736 7.56617C11.7998 7.71927 11.7284 7.88763 11.5614 7.97811C11.3943 8.06859 11.1724 8.05908 11.0184 7.95484C10.7322 7.7611 10.483 7.58801 10.26 7.43311C9.71125 7.0519 9.32104 6.78083 8.92677 6.58303C8.41984 6.32872 7.92078 6.20728 7.05452 6.20728H6.5227V7.41819C6.5227 7.56692 6.40838 7.70034 6.23497 7.75397C6.06157 7.8076 5.86479 7.7704 5.73973 7.66035L2.467 4.78035C2.30542 4.63816 2.31193 4.41951 2.48178 4.28363L5.75451 1.66545C5.8832 1.56249 6.07676 1.53169 6.24491 1.58741ZM3.42158 4.55002L5.6227 6.48701V5.84728C5.6227 5.64846 5.82418 5.48728 6.0727 5.48728H7.05452C8.05895 5.48728 8.73055 5.63473 9.40491 5.97305C9.71852 6.13038 10.0368 6.33247 10.3921 6.57335C10.1875 6.09407 9.94702 5.61443 9.61673 5.18505C9.02295 4.41313 8.17086 3.85092 6.72725 3.85092H6.0727C5.82418 3.85092 5.6227 3.68974 5.6227 3.49092V2.78913L3.42158 4.55002Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.75509 14.4126C6.58694 14.3569 6.4773 14.2256 6.4773 14.08L6.4773 12.8691H6.27275C4.44363 12.8691 3.33208 12.1222 2.61678 11.1923C2.06146 10.4704 1.72565 9.60849 1.45361 8.91029C1.38739 8.74031 1.32494 8.58003 1.26402 8.43383C1.20023 8.28073 1.2716 8.11237 1.43864 8.02189C1.60568 7.93141 1.82758 7.94092 1.98159 8.04516C2.26784 8.2389 2.517 8.41199 2.73998 8.56689C3.28875 8.9481 3.67896 9.21917 4.07323 9.41697C4.58016 9.67128 5.07922 9.79272 5.94548 9.79272H6.4773L6.4773 8.58181C6.4773 8.43308 6.59162 8.29966 6.76503 8.24603C6.93843 8.1924 7.13521 8.2296 7.26027 8.33965L10.533 11.2196C10.6946 11.3618 10.6881 11.5805 10.5182 11.7164L7.24549 14.3346C7.1168 14.4375 6.92324 14.4683 6.75509 14.4126ZM9.57842 11.45L7.3773 9.51299L7.3773 10.1527C7.3773 10.3515 7.17582 10.5127 6.9273 10.5127H5.94548C4.94105 10.5127 4.26945 10.3653 3.59509 10.027C3.28148 9.86962 2.96319 9.66753 2.60788 9.42665C2.81253 9.90593 3.05298 10.3856 3.38327 10.815C3.97705 11.5869 4.82914 12.1491 6.27275 12.1491H6.9273C7.17582 12.1491 7.3773 12.3103 7.3773 12.5091V13.2109L9.57842 11.45Z" fill="white"/>
    </svg>
  )
}

export default RefreshIcon;
