import React from "react";
import { Text } from "monday-ui-react-core";

import GoogleDriveIcon from "../../../resources/icons/GoogleDriveIcon";
import Button from "../../../components/Button";
import useGoogleConnection from "../../../hooks/services/googleHook";



function DriveConnection() {
  const { userLogin, isLoading } = useGoogleConnection();

  return (
    <div className="flex justify-center items-center flex-col gap-y-3">
      <GoogleDriveIcon className="w-20 h-20" />
      <Text className="text-grayed text-3xl">Connect to Google Drive</Text>
      <Text className="max-w-[400px] text-wrap text-center text-xs">
        Connect GSFI to your drive to read, write and sync data to/fro 
        on monday.com and Google Sheet
      </Text>

      <Button onClick={userLogin} loading={isLoading} disabled={isLoading}>
        Connect to Drive
      </Button>
    </div>
  )
}

export default DriveConnection;
