import { 
  LuExpand, 
  LuArrowRightLeft, 
  LuInfo,
  LuSearch,
  LuUser,
  LuPlus,
  LuFileSpreadsheet,
  LuTrash,
  LuUsers
} from "react-icons/lu";
import { BiSolidGridAlt } from "react-icons/bi";
import { LiaSortAlphaDownSolid } from "react-icons/lia";
import { 
  FaFolder, 
  FaRobot 
} from "react-icons/fa";
import { GoDownload } from "react-icons/go";
import { IoWarningOutline } from "react-icons/io5";
import { GrConnect } from "react-icons/gr";
import { MdClose, MdStarBorder } from "react-icons/md";




export const ExpandIcon = LuExpand;
export const InOutArrowIcon = LuArrowRightLeft;
export const CircledInfoIcon = LuInfo;
export const SearchIcon = LuSearch;
export const GridIcon = BiSolidGridAlt;
export const SortIcon = LiaSortAlphaDownSolid;
export const FolderIcon = FaFolder;
export const RobotIcon = FaRobot;
export const DownloadIcon = GoDownload;
export const WarningIcon = IoWarningOutline;
export const LinkConnect = GrConnect;
export const UserIcon = LuUser;
export const SharedIcon = LuUsers;
export const StarredIcon = MdStarBorder;
export const AddIcon = LuPlus;
export const SheetIcon = LuFileSpreadsheet;
export const DeleteIcon = LuTrash;
export const RemoveIcon = MdClose;


