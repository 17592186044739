import React from "react";
import { Text } from "monday-ui-react-core";

import Button from "../../../components/Button";
import GoogleDriveConnectedIcon from "../../../resources/icons/GoogleDriveConnectedIcon";


type IProps = {
  onSelectFile?: () => void;
}

function LoadSheet({
  onSelectFile
}: IProps) {


  return (
    <div className="flex justify-center items-center flex-col gap-y-3 h-full w-full">
      <GoogleDriveConnectedIcon className="w-40 h-40"/>
      <Text className="text-grayed text-3xl">Drive is Connected</Text>
      <Text className="max-w-[400px] text-wrap text-center text-xs">
        Select a file from drive to operate with
      </Text>

      <Button onClick={onSelectFile}>
        Load New Sheet
      </Button>
    </div>
  )
}

export default LoadSheet;
