export const __GLOBAL_STORAGE_KEY = process.env.REACT_APP_GLOBAL_STORAGE_KEY as string;
export const MONDAY_CLIENT_ID = process.env.REACT_APP_MONDAY_CLIENT_ID as string;
export const MONDAY_APP_VERSION = process.env.REACT_APP_MONDAY_APP_VERSION as string;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
export const MONDAY_UPGRADE_LINK = process.env.REACT_APP_MONDAY_UPGRADE_LINK as string;

// Google Services Keys
export const GC_API_KEY = process.env.REACT_APP_GD_API_KEY as string;
export const GC_CLIENT_ID = process.env.REACT_APP_GD_CLIENT_ID as string;
export const GC_SCOPES = [
  "https://www.googleapis.com/auth/drive.metadata.readonly",
  "https://www.googleapis.com/auth/spreadsheets",
].join(" ");
