import api from ".";
import { IGetBoardViewResponse, IGetObjectViewResponse } from "../resources/types/responses";
import Logger from "../utilities/Logger";
import { EXPORT_BOARD_TO_SHEET, GET_OBJECT_VIEW_DATA, GET_VIEW_DATA, LINK_DOC, LINK_DOC_TO_OBJECT } from "./uri";



export async function embedDocToViewRequest(
  userId: string, 
  boardId: string,
  workspaceId: string,
  docId: string,
  googleToken: string,
  viewId: string,
  googleId?: string
) {
  try {
    await api.post(LINK_DOC, {
      user_id: userId,
      board_id: boardId.toString(),
      workspace_id: workspaceId,
      doc_id: docId,
      google_token: googleToken,
      view_id: viewId,
      google_id: googleId
    });
    return true;

  } catch (error) {
    Logger.error(error);
    return false
    // throw error;
  }
}

export async function getBoardViewDataRequest(
  userId: string, 
  boardId: string,
  viewId: string,
  googleId?: string
) {
  try {
    const response = await api.post<IGetBoardViewResponse>(GET_VIEW_DATA, {
      user_id: userId,
      board_id: boardId.toString(),
      view_id: viewId,
      google_id: googleId
    });
    return response.data;

  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

export async function embedDocToObjectRequest(
  userId: string, 
  objectId: string,
  workspaceId: string,
  docId: string,
  googleToken: string,
  googleId?: string
) {
  try {
    await api.post(LINK_DOC_TO_OBJECT, {
      user_id: userId,
      object_id: objectId.toString(),
      workspace_id: workspaceId,
      doc_id: docId,
      google_token: googleToken,
      google_id: googleId
    });
    return true;

  } catch (error) {
    Logger.error(error);
    return false
  }
}

export async function getObjectViewDataRequest(
  userId: string, 
  objectId: string,
  googleId?: string
) {
  try {
    const response = await api.post<IGetObjectViewResponse>(GET_OBJECT_VIEW_DATA, {
      user_id: userId,
      object_id: objectId.toString(),
      google_id: googleId
    });
    return response.data;

  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

export async function exportBoardToSheetRequest(
  userId: string, 
  boardId: string,
) {
  try {
    await api.post(EXPORT_BOARD_TO_SHEET, {
      user_id: userId,
      board_id: boardId.toString(),
    });
    return true;

  } catch (error) {
    Logger.error(error);
    return false;
  }
}