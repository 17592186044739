class Logger {

  static info(message: unknown, ...optionalMessage: unknown[]) {
    console.info( message, optionalMessage );
  }

  static error(message: unknown, ...optionalMessage: unknown[]) {
    console.error( message, optionalMessage );
  }

  static log(message: unknown, ...optionalMessage: unknown[]) {
    console.log( message, optionalMessage );
  }

  static warn(message: unknown, ...optionalMessage: unknown[]) {
    console.warn( message, optionalMessage );
  }

}

export default Logger;
