import { AxiosError } from "axios";
import api from ".";
import { IGoogleAuthRefreshTokenResponse, IGoogleAuthTokenResponse } from "../resources/types/responses";
import Logger from "../utilities/Logger";
import { GOOGLE_AUTH, GOOGLE_AUTH_REFRESH_TOKEN, USER_VERIFICATION } from "./uri";


export async function googleAuthRequest(code: string) {
  try {
    const response  = await api.post<IGoogleAuthTokenResponse>(GOOGLE_AUTH, {
      code
    });

    return response.data;

  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

export async function googleTokenRefreshRequest(refreshToken: string) {
  try {
    const response  = await api.get<IGoogleAuthRefreshTokenResponse>(
      GOOGLE_AUTH_REFRESH_TOKEN+`?refresh_token=${refreshToken}`
    );

    return response.data;

  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

export async function verifyUser(userId: string, accountId:string) {
  try {
    const response  = await api.get(USER_VERIFICATION+`?user_id=${userId}&account_id=${accountId}`);
    return response.data;

  } catch (error: AxiosError | Error | any) {
    Logger.error(error);
    return error;
  }
}