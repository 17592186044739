import _ from "lodash";
import { GET_BOARD_COLUMNS, GET_BOARD_ITEMS_QUERY, GET_BOARD_ITEMS_QUERY_BY_LIMIT } from "../../resources/queries/board";
import { IBoard, } from "../../resources/types/responses";
import MondayService from "../../services/MondayService";
import { median, percentageOf, round } from "../../utilities";
import Logger from "../../utilities/Logger";



const mondayService = new MondayService();

export async function getUserBoardItems(boardId: string) {
  try {
    const response = await mondayService.query<{ boards: IBoard[] }>(
      GET_BOARD_ITEMS_QUERY(boardId)
    );
    
    return response.data.boards;

  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

//get all items from board
export async function getAllBoardItemsAndHeaders(
  boardId: string,
  columnFilter?: string[]
) {
  try {
    const items: string[][] = [];
    let cursor = null;
    let headers: string[] = [];
    
    do {
      const response = (await mondayService.query<{ boards: IBoard[] }>(
        GET_BOARD_ITEMS_QUERY_BY_LIMIT(
          boardId,
          500,
          cursor
        )
      )) as {
        data: {
          boards: IBoard[];
        };
        account_id: number;
      };

      const foundBoard = response.data.boards[0];
      // Filters out the subitems column's header
      if(headers.length < 1) {
        headers = (foundBoard.columns ?? [])
          .filter(c => !(c.id == "subitems" || c.id.includes("subitems")))
          // Filter headers by column filter if available
          .filter(c => columnFilter && columnFilter.length? columnFilter.includes(c.id) : c)
          .map((c) => c.title);
      }

      // Transform board items to sheet rows
      const rows = foundBoard.items_page.items.map((item) => {
        const columnValues = item.column_values
          // Filters out the subitems column's values
          .filter(c => !(c.id == "subitems" || c.id.includes("subitems")))
          // Filter headers by column filter if available
          .filter(c => columnFilter && columnFilter.length? columnFilter.includes(c.id) : c)
          .map((cv) => {
            // Handle Mirror Column Values
            if(cv.type == "mirror") {              
              const mirroredItems = cv.mirrored_items ?? [];
              const isStatusType = mirroredItems.every(item => item.mirrored_value?.__typename == "StatusValue")
              const isNumberType = mirroredItems.every(item => item.mirrored_value?.__typename == "NumbersValue")

              // Handle Status Column Percentage
              if(isStatusType) {
                let str = ""
                const totalMatch = cv.display_value?.split(",").map(item => item.trim())??[];
                const uniqMatches = _.uniq(totalMatch);
                uniqMatches.forEach(label => {
                  const matchLabels = totalMatch.filter(match => match == label);
                  const labelPercent = percentageOf(matchLabels.length, mirroredItems.length)
                  str += labelPercent > 0
                    ? `${str.length > 0? ", " : ""}${label}-${labelPercent}%` : ""
                });

                return str;
              }

              // Handle Number Column computations
              else if(isNumberType) {
                const settingsStr = cv.column?.settings_str;

                if(settingsStr) {
                  const settings = JSON.parse(settingsStr);
                  const method = settings?.function as string;
                  const totalMatch = cv.display_value?.split(",").map(
                    item => {
                      const numb = parseFloat(item.trim());
                      return numb || 0;
                    }
                  )??[];

                  // Median Computations
                  if(method && method == "median") {
                    const result = median(totalMatch);                    
                    return `${result > 0? result : "0"}`;
                  } 
                  // Sum Computations
                  else if(method && method == "sum") {
                    const result = round(_.sum(totalMatch), 3);
                    return `${result > 0? result : "0"}`;
                  }
                  // Average Computations
                  else if(method && method == "average") {
                    const result = round(_.mean(totalMatch), 3);
                    return `${result > 0? result : "0"}`;
                  }
                  // Min Computations
                  else if(method && method == "min") {
                    const result =round( _.min(totalMatch)??0, 3);
                    return `${result > 0? result : "0"}`;
                  }
                  // Max Computations
                  else if(method && method == "max") {
                    const result = round(_.max(totalMatch)??0, 3);
                    return `${result > 0? result : "0"}`;
                  }
                  // Count Computations
                  else if(method && method == "count") {
                    const result = totalMatch.length;
                    return `${result > 0? result : "0"}`;
                  }
                }
              }
            }

            return cv.text ?? cv.display_value ?? "";
          });
        
        return [
          item.name,
          ...columnValues
        ];
      });

      items.push(...rows);
      cursor = response.data.boards[0].items_page?.cursor;
    } while (cursor);

    return {
      items,
      headers
    };
  } catch (error) {
    Logger.error("Error in getAllItems function:", error);
    throw error;
  }
}

export async function getBoardColumns(boardId: string) {
  try {
    const response = await mondayService.query<{ boards: IBoard[] }>(
      GET_BOARD_COLUMNS(boardId)
    );
    
    return response.data.boards[0].columns;
    
  } catch (error) {
    Logger.error(error);
    throw error;
  }
}