import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";



type Props = {
  classes?: {
    wrapperClassName?: string;
    textClassName?: string;
    inputClassName?: string;
  },
  value: string;
  isNav?: boolean;
  isReadonly?: boolean;
  onValueChange?: (value: string) => void;
}

function CustomEditableText({
  classes,
  value,
  isNav = false,
  isReadonly = false,
  onValueChange,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [textValue, setTextValue] = useState(value);
  const [isEditMode, setEditMode] = useState(false);


  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    setTextValue(v);
  }

  const handleOnBlur = () => {
    setEditMode(false);
    if(!textValue) {
      setTextValue(value);
      onValueChange && onValueChange(value);
    }
    
    onValueChange && onValueChange(textValue);
  }

  const toggleEditMode = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(e.detail == 2 && !isReadonly) {
      setEditMode(true);
    }
  }



  useEffect(() => {
    if(isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode, inputRef.current]);

  useEffect(() => {
    setTextValue(value);
  }, [value])




  return (
    <div className={clsx("w-full", classes?.wrapperClassName)}>
      {isEditMode ? (
        <input 
          ref={inputRef}
          value={textValue} 
          onChange={handleValueChange}
          onBlur={handleOnBlur}
          className={clsx(
            classes?.inputClassName,
            !isNav && "text-gray-600 border-none outline-none px-1 py-[1px] w-full",
            !isNav && "rounded-sm focus:border-none active:border-none",
            
          )}
        />
      ) : (
        <div 
          className={clsx("truncate", classes?.textClassName)} 
          onClick={toggleEditMode}
        >
          {textValue}
        </div>
      )}
    </div>
  )
}

export default CustomEditableText;