import React from 'react'

type Props = {
  className?: string
}

function GoogleDriveConnectedIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <path fill="#007038" d="M87.215,56.71C88.35,54.555,89,52.105,89,49.5c0-6.621-4.159-12.257-10.001-14.478 C78.999,35.015,79,35.008,79,35c0-11.598-9.402-21-21-21c-9.784,0-17.981,6.701-20.313,15.757C36.211,29.272,34.638,29,33,29 c-7.692,0-14.023,5.793-14.89,13.252C12.906,43.353,9,47.969,9,53.5C9,59.851,14.149,65,20.5,65c0.177,0,0.352-0.012,0.526-0.022 C21.022,65.153,21,65.324,21,65.5C21,76.822,30.178,86,41.5,86c6.437,0,12.175-2.972,15.934-7.614C59.612,80.611,62.64,82,66,82 c4.65,0,8.674-2.65,10.666-6.518C77.718,75.817,78.837,76,80,76c6.075,0,11-4.925,11-11C91,61.689,89.53,58.727,87.215,56.71z" />
      <path fill="#fdfcef" d="M77.5,52.5V53h3v-0.5c0,0,4.242,0,5.5,0c2.485,0,4.5-2.015,4.5-4.5c0-2.333-1.782-4.229-4.055-4.455 C86.467,43.364,86.5,43.187,86.5,43c0-2.485-2.015-4.5-4.5-4.5c-1.438,0-2.703,0.686-3.527,1.736 C78.333,37.6,76.171,35.5,73.5,35.5c-2.761,0-5,2.239-5,5c0,0.446,0.077,0.87,0.187,1.282C68.045,41.005,67.086,40.5,66,40.5 c-1.781,0-3.234,1.335-3.455,3.055C62.364,43.533,62.187,43.5,62,43.5c-2.485,0-4.5,2.015-4.5,4.5s2.015,4.5,4.5,4.5s9.5,0,9.5,0 H77.5z" />
      <path fill="#472b29" d="M73.5,35c-3.033,0-5.5,2.467-5.5,5.5c0,0.016,0,0.031,0,0.047C67.398,40.192,66.71,40,66,40 c-1.831,0-3.411,1.261-3.858,3.005C62.095,43.002,62.048,43,62,43c-2.757,0-5,2.243-5,5s2.243,5,5,5h15.5 c0.276,0,0.5-0.224,0.5-0.5S77.776,52,77.5,52H62c-2.206,0-4-1.794-4-4s1.794-4,4-4c0.117,0,0.23,0.017,0.343,0.032l0.141,0.019 c0.021,0.003,0.041,0.004,0.062,0.004c0.246,0,0.462-0.185,0.495-0.437C63.232,42.125,64.504,41,66,41 c0.885,0,1.723,0.401,2.301,1.1c0.098,0.118,0.241,0.182,0.386,0.182c0.078,0,0.156-0.018,0.228-0.056 c0.209-0.107,0.314-0.346,0.254-0.573C69.054,41.218,69,40.852,69,40.5c0-2.481,2.019-4.5,4.5-4.5c2.381,0,4.347,1.872,4.474,4.263 c0.011,0.208,0.15,0.387,0.349,0.45c0.05,0.016,0.101,0.024,0.152,0.024c0.15,0,0.296-0.069,0.392-0.192 C79.638,39.563,80.779,39,82,39c2.206,0,4,1.794,4,4c0,0.117-0.017,0.23-0.032,0.343l-0.019,0.141 c-0.016,0.134,0.022,0.268,0.106,0.373s0.207,0.172,0.34,0.185C88.451,44.247,90,45.949,90,48c0,2.206-1.794,4-4,4h-5.5 c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5H86c2.757,0,5-2.243,5-5c0-2.397-1.689-4.413-4.003-4.877 C86.999,43.082,87,43.041,87,43c0-2.757-2.243-5-5-5c-1.176,0-2.293,0.416-3.183,1.164C78.219,36.76,76.055,35,73.5,35L73.5,35z" />
      <path fill="#472b29" d="M72,42c-1.403,0-2.609,0.999-2.913,2.341C68.72,44.119,68.301,44,67.875,44 c-1.202,0-2.198,0.897-2.353,2.068C65.319,46.022,65.126,46,64.938,46c-1.529,0-2.811,1.2-2.918,2.732 C62.01,48.87,62.114,48.99,62.251,49c0.006,0,0.012,0,0.018,0c0.13,0,0.24-0.101,0.249-0.232c0.089-1.271,1.151-2.268,2.419-2.268 c0.229,0,0.47,0.042,0.738,0.127c0.022,0.007,0.045,0.01,0.067,0.01c0.055,0,0.11-0.02,0.156-0.054 C65.962,46.537,66,46.455,66,46.375c0-1.034,0.841-1.875,1.875-1.875c0.447,0,0.885,0.168,1.231,0.473 c0.047,0.041,0.106,0.063,0.165,0.063c0.032,0,0.063-0.006,0.093-0.019c0.088-0.035,0.148-0.117,0.155-0.212 C69.623,43.512,70.712,42.5,72,42.5c0.208,0,0.425,0.034,0.682,0.107c0.023,0.007,0.047,0.01,0.07,0.01 c0.109,0,0.207-0.073,0.239-0.182c0.038-0.133-0.039-0.271-0.172-0.309C72.517,42.04,72.256,42,72,42L72,42z" />
      <path fill="#472b29" d="M85.883,43.5c-1.326,0-2.508,0.897-2.874,2.182c-0.038,0.133,0.039,0.271,0.172,0.309 C83.205,45.997,83.228,46,83.25,46c0.109,0,0.209-0.072,0.24-0.182C83.795,44.748,84.779,44,85.883,44 c0.117,0,0.23,0.014,0.342,0.029c0.012,0.002,0.023,0.003,0.035,0.003c0.121,0,0.229-0.092,0.246-0.217 c0.019-0.137-0.077-0.263-0.214-0.281C86.158,43.516,86.022,43.5,85.883,43.5L85.883,43.5z" />
      <path fill="#fff" d="M15.5,51h-10C5.224,51,5,50.776,5,50.5S5.224,50,5.5,50h10c0.276,0,0.5,0.224,0.5,0.5 S15.777,51,15.5,51z" />
      <path fill="#fff" d="M18.5,51h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5 S18.777,51,18.5,51z" />
      <path fill="#fff" d="M23.491,53H14.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8.991c0.276,0,0.5,0.224,0.5,0.5 S23.767,53,23.491,53z" />
      <path fill="#fff" d="M12.5,53h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5 S12.777,53,12.5,53z" />
      <path fill="#fff" d="M9.5,53h-2C7.224,53,7,52.776,7,52.5S7.224,52,7.5,52h2c0.276,0,0.5,0.224,0.5,0.5S9.777,53,9.5,53z" />
      <path fill="#fff" d="M15.5,55h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S15.776,55,15.5,55z" />
      <path fill="#fff" d="M18.5,46c-0.177,0-0.823,0-1,0c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5c0.177,0,0.823,0,1,0 c0.276,0,0.5-0.224,0.5-0.5S18.776,46,18.5,46z" />
      <path fill="#fff" d="M18.5,48c-0.177,0-4.823,0-5,0c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5c0.177,0,4.823,0,5,0 c0.276,0,0.5-0.224,0.5-0.5S18.776,48,18.5,48z" />
      <path fill="#fff" d="M23.5,50c-0.177,0-2.823,0-3,0c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5c0.177,0,2.823,0,3,0 c0.276,0,0.5-0.224,0.5-0.5S23.776,50,23.5,50z" />
      <g>
        <path fill="#fff" d="M72.5,24h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5 S72.776,24,72.5,24z" />
        <path fill="#fff" d="M76.5,24h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S76.776,24,76.5,24z" />
        <path fill="#fff" d="M81.5,26h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5 S81.777,26,81.5,26z" />
        <path fill="#fff" d="M69.5,26h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5 S69.776,26,69.5,26z" />
        <path fill="#fff" d="M66.47,26H64.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1.97c0.276,0,0.5,0.224,0.5,0.5 S66.746,26,66.47,26z" />
        <path fill="#fff" d="M75.5,22h-5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5 S75.777,22,75.5,22z" />
        <path fill="#fff" d="M72.5,28h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S72.776,28,72.5,28z" />
      </g>
      <g>
        <polygon fill="#fcc938" points="77.176,57.5 77.465,57 60.67,28 43.907,28 60.993,57.5" />
        <polygon fill="#1e65d6" points="43.008,57.5 34.899,71.502 35.186,72 68.78,72 77.176,57.5" />
        <polygon fill="#67ba8c" points="34.899,71.502 52,41.973 43.907,28 43.325,28 26.53,57"/>
        <g>
          <rect width="33.013" height="1" x="44.163" y="57" fill="#472b29" />
          <rect width="32.97" height="1" x="26.676" y="56.738" fill="#472b29" transform="rotate(-59.927 43.162 57.238)" />
          <rect width="1" height="32.934" x="51.659" y="25.78" fill="#472b29" transform="rotate(-30.08 52.16 42.248)" />

          <path fill="#472b29" d="M30.429,57.379c-0.064,0-0.129-0.016-0.188-0.05c-0.18-0.104-0.241-0.333-0.137-0.513 l13.693-23.645c0.104-0.18,0.335-0.24,0.512-0.137c0.18,0.104,0.241,0.333,0.137,0.513L30.753,57.192 C30.684,57.313,30.558,57.379,30.429,57.379z" />
          <path fill="#472b29" d="M72.194,55c-0.129,0-0.255-0.067-0.324-0.187l-0.724-1.25c-0.104-0.179-0.043-0.409,0.137-0.513 c0.175-0.103,0.407-0.044,0.512,0.137l0.724,1.25c0.104,0.179,0.043,0.409-0.137,0.513C72.323,54.984,72.259,55,72.194,55z" />
          <path fill="#472b29" d="M66.981,46c-0.129,0-0.255-0.067-0.324-0.187l-8.253-14.25c-0.104-0.179-0.043-0.409,0.137-0.513 c0.176-0.104,0.407-0.043,0.512,0.137l8.253,14.25c0.104,0.179,0.043,0.409-0.137,0.513C67.11,45.984,67.046,46,66.981,46z" />
          <path fill="#472b29" d="M70.457,52c-0.129,0-0.255-0.067-0.324-0.187l-1.883-3.25c-0.104-0.179-0.043-0.409,0.137-0.513 c0.175-0.103,0.407-0.044,0.512,0.137l1.883,3.25c0.104,0.179,0.043,0.409-0.137,0.513C70.586,51.984,70.521,52,70.457,52z" />
          <path fill="#472b29" d="M66.837,69h-1.462C65.168,69,65,68.832,65,68.625s0.168-0.375,0.375-0.375h1.462 c0.207,0,0.375,0.168,0.375,0.375S67.044,69,66.837,69z" />
          <path fill="#472b29" d="M63.625,69h-2.25C61.168,69,61,68.832,61,68.625s0.168-0.375,0.375-0.375h2.25 c0.207,0,0.375,0.168,0.375,0.375S63.832,69,63.625,69z" />
          <path fill="#472b29" d="M58.625,69H39.454c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h19.171 c0.207,0,0.375,0.168,0.375,0.375S58.832,69,58.625,69z" />
          <path fill="#472b29" d="M60.443,28.4L77.007,57l-8.456,14.6H35.417l-8.425-14.599L43.557,28.4H60.443 M60.674,27H43.327 c-0.357,0-0.687,0.19-0.865,0.499L25.665,56.5c-0.179,0.31-0.18,0.691-0.001,1.001L34.32,72.5c0.179,0.309,0.509,0.5,0.866,0.5 h33.596c0.357,0,0.687-0.19,0.865-0.499l8.687-15c0.18-0.31,0.18-0.692,0-1.002l-16.796-29C61.36,27.19,61.031,27,60.674,27 L60.674,27z" />
          <path fill="#472b29" d="M52,43.769L59.431,56.6H44.569L52,43.769 M52,40.976L42.141,58H61.86L52,40.976L52,40.976z" />
        </g>
      </g>
      <g>
        <path fill="#fdfcef" d="M36.5,74.5c0,0,1.567,0,3.5,0s3.5-1.567,3.5-3.5c0-1.781-1.335-3.234-3.055-3.455 C40.473,67.366,40.5,67.187,40.5,67c0-1.933-1.567-3.5-3.5-3.5c-1.032,0-1.95,0.455-2.59,1.165 c-0.384-1.808-1.987-3.165-3.91-3.165c-2.209,0-4,1.791-4,4c0,0.191,0.03,0.374,0.056,0.558C26.128,65.714,25.592,65.5,25,65.5 c-1.228,0-2.245,0.887-2.455,2.055C22.366,67.527,22.187,67.5,22,67.5c-1.933,0-3.5,1.567-3.5,3.5s1.567,3.5,3.5,3.5s7.5,0,7.5,0 V75h7V74.5z" />
        <path fill="#472b29" d="M38.25,70C38.112,70,38,69.888,38,69.75c0-1.223,0.995-2.218,2.218-2.218 c0.034,0.009,0.737-0.001,1.244,0.136c0.133,0.036,0.212,0.173,0.176,0.306c-0.036,0.134-0.173,0.213-0.306,0.176 c-0.444-0.12-1.1-0.12-1.113-0.118c-0.948,0-1.719,0.771-1.719,1.718C38.5,69.888,38.388,70,38.25,70z" />
        <circle cx="31.5" cy="74.5" r=".5" fill="#472b29" />
        <path fill="#472b29" d="M40,75h-3.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H40c1.654,0,3-1.346,3-3 c0-1.496-1.125-2.768-2.618-2.959c-0.134-0.018-0.255-0.088-0.336-0.196s-0.115-0.244-0.094-0.377C39.975,67.314,40,67.16,40,67 c0-1.654-1.346-3-3-3c-0.85,0-1.638,0.355-2.219,1c-0.125,0.139-0.321,0.198-0.5,0.148c-0.182-0.049-0.321-0.195-0.36-0.379 C33.58,63.165,32.141,62,30.5,62c-1.93,0-3.5,1.57-3.5,3.5c0,0.143,0.021,0.28,0.041,0.418c0.029,0.203-0.063,0.438-0.242,0.54 s-0.396,0.118-0.556-0.01C25.878,66.155,25.449,66,25,66c-0.966,0-1.792,0.691-1.963,1.644c-0.048,0.267-0.296,0.446-0.569,0.405 C22.314,68.025,22.16,68,22,68c-1.654,0-3,1.346-3,3s1.346,3,3,3h7.5c0.276,0,0.5,0.224,0.5,0.5S29.776,75,29.5,75H22 c-2.206,0-4-1.794-4-4s1.794-4,4-4c0.059,0,0.116,0.002,0.174,0.006C22.588,65.82,23.711,65,25,65c0.349,0,0.689,0.061,1.011,0.18 C26.176,62.847,28.126,61,30.5,61c1.831,0,3.466,1.127,4.153,2.774C35.333,63.276,36.155,63,37,63c2.206,0,4,1.794,4,4 c0,0.048-0.001,0.095-0.004,0.142C42.739,67.59,44,69.169,44,71C44,73.206,42.206,75,40,75z"
      />
        <path fill="#472b29" d="M34.5,74c-0.159,0-0.841,0-1,0c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5c0.159,0,0.841,0,1,0 c0.276,0,0.5-0.224,0.5-0.5S34.776,74,34.5,74z" />
      </g>
    </svg>
  )
}

export default GoogleDriveConnectedIcon;
