import { SUPPORTED_OS } from "../resources/constants";
import { BROWSER_OS } from "../resources/enums";

export function getCurrentBrowserOS() {
  if(navigator) {
    if (navigator.userAgent.indexOf(BROWSER_OS.WINDOWS) != -1) return SUPPORTED_OS.Win;
    else if (navigator.userAgent.indexOf(BROWSER_OS.MAC_OS) != -1) return SUPPORTED_OS.Mac;
    else if (navigator.userAgent.indexOf(BROWSER_OS.UNIX) != -1) return SUPPORTED_OS.X11;
    else if (navigator.userAgent.indexOf(BROWSER_OS.LINUX) != -1) return SUPPORTED_OS.Linux;
  }

  return null;
}

export function isArraySubset(arr1: string[], arr2: string[]): boolean {
  // Use a Set for arr2 for efficient lookup
  const arr2Set = new Set(arr2);

  // Check if every element in arr1 is present in arr2Set
  return arr1.every(item => arr2Set.has(item));
}

export function truncatePath(path: string, maxLength: number): string {
  if (path.length <= maxLength) {
    return path;
  }

  const ellipsis = '...';
  const endLength = maxLength - ellipsis.length;
  const truncatedEnd = path.slice(-endLength);

  return `${ellipsis}${truncatedEnd}`;
}

// Arithmetic
export function round(value: number, precision = 1) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function percentageOf(numb: number, total: number) {
  if(numb > total) return 0;

  const result =  (numb * 100) / total;
  return round(result);
}

export function median(values: number[]) {
  if (values.length === 0) return 0;

  // Sorting values, preventing original array
  // from being mutated.
  values = [...values].sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  return round((values.length % 2
    ? (values[half] || 0)
    : ((values[half - 1] || 0) + (values[half] || 0)) / 2
  ), 3);

}