import { BROWSER_OS } from "./enums";
import { IBrowserOS } from "./types";

export const SUPPORTED_OS: Record<BROWSER_OS, IBrowserOS> = {
  [BROWSER_OS.MAC_OS]: {
    id: BROWSER_OS.MAC_OS,
    name: "MacOS",
  },
  [BROWSER_OS.WINDOWS]: {
    id: BROWSER_OS.WINDOWS,
    name: "Windows",
  },
  [BROWSER_OS.UNIX]: {
    id: BROWSER_OS.UNIX,
    name: "Unix"
  },
  [BROWSER_OS.LINUX]: {
    id: BROWSER_OS.LINUX,
    name: "Linux"
  }
}