import { useEffect, useState } from "react";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import axios, { AxiosError } from "axios";
import useSWR from "swr";

import { GC_SCOPES } from "../../configs";
import { SIGN_GOOGLE_OUT, UPDATE_GOOGLE, UPDATE_USER_ACCOUNT } from "../../states/slices/userSlice";
import Logger from "../../utilities/Logger";
import { IFile, IFileParent, IGoogleUserInfoResponse } from "../../resources/types";
import { googleAuthRequest, googleTokenRefreshRequest } from "../../requests/authRequests";
import useUser from "../states/userHook";
import GoogleDriveService from "../../services/GoogleDriveService";
import { isArraySubset } from "../../utilities";



type IGoogleConnOptions = {
  init?: boolean;
}

const googleDriveService = new GoogleDriveService();

function useGoogleConnection(options?: IGoogleConnOptions) {
  const {GoogleDetails} = useUser();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);


  const getUserInfo = async (token: string) =>{
    try {
      setLoading(true);
      const userInfoResponse = await axios.get<IGoogleUserInfoResponse>(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${token}`  } },
      );

      return userInfoResponse.data;
      
    } catch (error) {
      Logger.error(error);
      throw error;

    } finally {
      setLoading(false);
    }
  }

  //this handles google auth
  const userLogin = useGoogleLogin({
    scope: GC_SCOPES,
    flow: "auth-code",
    onSuccess: async (response) => {
      try {
        setLoading(true);
        const resp = await googleAuthRequest(response.code);
        const userInfo = await getUserInfo(resp.access_token);
        
        if(userInfo.email_verified) {
          const isAcceptAllPermissions = isArraySubset(GC_SCOPES.split(" "), resp?.scope.split(" "))
          dispatch(UPDATE_USER_ACCOUNT({
            google: {
              ref: userInfo.sub,
              token: resp.access_token,
              profile: userInfo.picture,
              email: userInfo.email,
              name: {
                firstName: userInfo.given_name,
                lastName: userInfo.family_name,
                fullName: userInfo.name
              },
              refresh_token: resp.refresh_token,
              id_token: resp.id_token,
              isAcceptAllPermissions: isAcceptAllPermissions
            }
          }));

        } else {
          // TODO: Show toast for unverified email
        }

      } catch (error) {
        Logger.error(error);
        // TODO: Show a toast on error
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      setLoading(false);
      Logger.error(error);
      dispatch(SIGN_GOOGLE_OUT());

      // TODO: Show a toast on error
    },
    select_account: true
  });

  //this handles logout
  const userSignOut = () => {
    try {
      googleLogout();
      dispatch(SIGN_GOOGLE_OUT());

    } catch (error) {
      Logger.error(error)
      // TODO: Show a toast on error
    }
  }

  // this handles refreshing the google token
  const refreshToken = async (refreshToken?: string) => {
    try {   
      if(refreshToken || GoogleDetails.refresh_token) {
        const resp = await googleTokenRefreshRequest(
          (refreshToken??GoogleDetails.refresh_token) as string
        );
        
        dispatch(UPDATE_GOOGLE({
          email: GoogleDetails.email,
          id_token: resp.id_token,
          name: GoogleDetails.name,
          profile: GoogleDetails.profile,
          ref: GoogleDetails.ref,
          refresh_token: GoogleDetails.refresh_token,
          token: resp.access_token
        }));

        return resp.access_token;
      }  
      
    } catch (error) {
      Logger.error(error);
      throw error;
    }
  }


  useEffect(() => {
    if(options?.init) {
      googleDriveService.init();
    }
    
  }, [options?.init]);
  


  return {
    userLogin,
    isLoading,
    userSignOut,
    refreshToken
  }
}

export default useGoogleConnection;




// DRIVE ACTIONS
export function useGoogleDrive() {
  const { GoogleDetails } = useUser();
  const {refreshToken} = useGoogleConnection();

  //get a file by the fileId
  const findFileById = async (fileId: string, token?: string, gen = 0): Promise<IFileParent | null> => {
    try {
      const gToken = token || GoogleDetails?.token;
      if(gToken) {
        const response = await googleDriveService.getParent(fileId, gToken);
        return response;
      }

      return null;
      
    } catch (error: AxiosError | Error | any) {
      try {        
        if(error?.status == 401 && gen <= 2) { // Refresh token
          const access_token = await refreshToken();
          return await findFileById(fileId, access_token, gen + 1);
        }

        return null;
        
      } catch (error) {
        Logger.error(error);
        throw error;
      }
    }
  }
  
  /**
   * Token was passed after refresh due to redux state not refreshed 
   * globally before request.
   * @param gen 
   * @param token 
   * @returns 
   */
  //Fetches all the google sheet files in the connected drive
  const fetchFiles = async (token?: string, gen = 0): Promise<Array<IFile>> =>{
    try {
      const gToken = token || GoogleDetails?.token;
      if(gToken) {
        const response = await googleDriveService.listFiles(gToken);
        return response;
      }

      return [];
      
    } catch (error: AxiosError | Error | any) {
      try {        
        if(error?.status == 401 && gen <= 2) { // Refresh token
          const access_token = await refreshToken();
          return await fetchFiles(access_token, gen + 1);
        }

        return [];
        
      } catch (error) {
        Logger.error(error);
        throw error;
      }
    }
  } 
  
  //fetches all the folders in the connected drive
  const fetchFolders = async (token?: string, gen = 0): Promise<Array<IFile>> =>{
    try {
      const gToken = token || GoogleDetails?.token;
      if(gToken) {
        const response = await googleDriveService.listFolders(gToken);
        return response;
      }

      return [];
      
    } catch (error: AxiosError | Error | any) {
      try {
        if(error?.status == 401 && gen <= 2) { // Refresh token
          const access_token = await refreshToken();
          return await fetchFolders(access_token, gen + 1);
        }

        return [];
        
      } catch (error) {
        Logger.error(error);
        throw error;
      }
    }
  } 

  //fetches the subfolders in a folder
  const fetchSubFiles = async ( folderId: string, token?: string, gen = 0): Promise<Array<IFile>> => {
    try {
      const gToken = token || GoogleDetails?.token;
      if(gToken) {
        const response = await googleDriveService.getFolderChildren(
          folderId, 
          gToken
        );
        return response;
      }

      return []

    } catch (error: AxiosError | Error | any) {
      try {        
        if(error?.status == 401 && gen <= 2) { // Refresh token
          const access_token = await refreshToken();
          return await fetchSubFiles(folderId, access_token, gen + 1);
        }

        return [];
        
      } catch (error) {
        Logger.error(error);
        throw error;
      }
    }
  }


  return {
    fetchFiles,
    fetchFolders,
    fetchSubFiles,
    findFileById
  }
}



export function useDriveFiles() {
  const {GoogleDetails} = useUser();
  const [isRefreshing, setRefreshing] = useState(false)
  const {fetchFiles} = useGoogleDrive();
  const {
    data,
    isLoading,
    mutate,
  } = useSWR(
    `${GoogleDetails.email}-drive-files`,
    () => fetchFiles(),
    {
      refreshInterval: 1000 * 60 * 5, // 5 minute polling interval
      errorRetryCount: 3
    }
  );


  const refreshFiles = async () => {
    try {
      setRefreshing(true);
      await mutate();

    } finally {
      setRefreshing(false);
    }
  }


  return {
    filesData: data ?? [],
    isLoading,
    isRefreshing,
    refreshFiles,
  }
}

export function useDriveSubFiles(folderId?: string) {
  const {GoogleDetails} = useUser();
  const {fetchSubFiles} = useGoogleDrive();
  const {
    data,
    isLoading,
  } = useSWR(
    `${GoogleDetails.email}-${folderId}-drive-sub-files`,
    folderId? () => fetchSubFiles(folderId) : null,
    {
      refreshInterval: 1000 * 60 * 5, // 5 minute polling interval
      errorRetryCount: 3
    }
  );


  // const refreshFiles = async () => {
  //   try {
  //     setRefreshing(true);
  //     await mutate();

  //   } finally {
  //     setRefreshing(false);
  //   }
  // }


  return {
    filesData: data ?? [],
    isLoading,
    // isRefreshing,
    // refreshFiles,
  }
}

export function useDriveFolders() {
  const {GoogleDetails} = useUser();
  const {fetchFolders} = useGoogleDrive();
  const {
    data,
    isLoading,
  } = useSWR(
    `${GoogleDetails.email}-drive-folders`,
    async () => await fetchFolders(),
    {
      refreshInterval: 1000 * 60 * 5, // 5 minute polling interval
      errorRetryCount: 3
    }
  );



  return {
    folderData: data ?? [],
    isLoading,
  }
}