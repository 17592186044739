import React, { ReactElement, ReactNode, useCallback, useMemo, useState } from "react";
import { ModalContent } from "monday-ui-react-core";

import Modal from "../../components/Modal";
import { ModalWidth } from "monday-ui-react-core/dist/types/components/Modal/ModalHelper";


type IModalStates = {
  children: string | ReactElement ,
  backdrop: boolean,
  scrollable: boolean,
  // size: "md",
  modalClassName?: string,
  title?: string,
  showCloseButton: boolean,
  sticky: boolean,
  position: "auto",
  bodyContent: null,
  footerContent: null;
  width?: string | typeof ModalWidth
}

function useModal() {
  const [modalState, setModalState] = useState<IModalStates>({
    children: "",
    backdrop: true,
    scrollable: false,
    // size: "md",
    modalClassName: "",
    title: "Title",
    showCloseButton: true,
    sticky: false,
    position: "auto",
    bodyContent: null,
    footerContent: null
  });
  const [show, setShow] = useState(false);


  const showModal = (states: Partial<IModalStates & { show: boolean}>) =>{        
    setModalState({ ...modalState, ...states});
    setShow(true);
  }
    

  const closeModal = useCallback((callback?: () => void) => {    
    setShow(false); 

    if(callback && typeof callback === "function")
      callback();       
  }, []);


  const ModalComponent = () => (
    <Modal
      show={show}
      showCloseButton={modalState.showCloseButton}
      sticky={modalState.sticky}
      title={modalState.title}
      onClose={() => closeModal()}
      width={modalState.width}
      modalClassName={modalState.modalClassName}
      // scrollable={modalState.scrollable}
      // size={modalState.size}
      // position={modalState.position}
      // radius={modalState.radius}
      // titleClassName={modalState.titleClassName}
      // backdrop={modalState.backdrop}
      // backdropClassName={modalState.backdropClassName}
      // bodyContent={modalState.bodyContent}
      // bodyClassName={modalState.bodyClassName}
      // footerContent={modalState.footerContent}
      // footerClassName={modalState.footerClassName}
      // baseClassName={modalState.baseClassName}
      // backdropStyle={modalState.backdropStyle}
      // style={modalState.style}
    >
      <ModalContent>
        {modalState.children}
      </ModalContent>
    </Modal>
  );

  const MemoizedModalComponent = useMemo(ModalComponent, [modalState, show, closeModal]);


  return {
    MemoizedModalComponent,
    ModalComponent,
    showModal,
    closeModal,
  }
}

export default useModal;
