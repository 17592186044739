import React from "react";
import { Loader } from "monday-ui-react-core";
import "@silevis/reactgrid/styles.css";
import { GoogleSpreadsheetWorksheet } from "google-spreadsheet";

import Sheet from "./Sheet";
import SheetTabs from "./Sheet/SheetTabs";



type Props = {
  fullScreen?: boolean;
  isSyncing?: boolean;
  isLoadingFile?: boolean;
  activeSheet?: number;
  tabList?: Array<{title: string; id: number}>;
  activeSheetData?: GoogleSpreadsheetWorksheet;
  zoomLevel?: number;
  onChangeTab?: (tabIndex: number) => void;
  onAddSheet?: () => void;
  onDeleteSheet?: (sheetId: number) => void;
  updateSheet?: (sheetId: number, title: string) => void;
  sheetStatus?: string;
  isReadonly: boolean
}

function FilePreview({
  fullScreen,
  isLoadingFile,
  isSyncing,
  activeSheet = 0,
  tabList,
  activeSheetData,
  zoomLevel,
  sheetStatus,
  isReadonly,
  onChangeTab,
  onAddSheet,
  onDeleteSheet,
  updateSheet
}: Props) {
  
  return (
    <div className="w-full h-full">
      <div 
        id="sheet-wrapper"
        className="w-full h-full max-w-[100vw] overflow-auto" 
        style={{maxHeight: `calc(100vh - ${fullScreen? "30px" : "200px"})`}}
      >
        {isLoadingFile? (
          <div className="w-full flex justify-center items-center h-full">
            <Loader size={Loader.sizes.SMALL} />
          </div>
         ) : (
          <div className="bg-white w-fit ">
            <Sheet 
              sheetData={activeSheetData} 
              isSyncing={isSyncing} 
              zoomLevel={zoomLevel}
              status={sheetStatus}
              isReadonly={isReadonly}
            />
          </div>
        )}
      </div>

      {/* Sheet Tabs */}
      <SheetTabs 
        activeSheet={activeSheet}
        sheets={tabList}
        onChange={onChangeTab}
        onAddNewSheet={onAddSheet}
        onDeleteSheet={onDeleteSheet}
        onUpdateSheet={updateSheet}
        isReadonly={isReadonly}
      />
    </div>
  )
}

export default React.memo(FilePreview);