export const GET_ALL_BOARDS_QUERY = `
  query {
    boards{
      board_folder_id
      board_kind
      description
      id
      item_terminology
      items_count
      name
      permissions
      state
      tags {
        id
        color
        name
      }
      type
      updated_at
      url
      workspace {
        id
        name
      }
    }
  }
`;

export const GET_ALL_BOARDS_QUERY_BY_LIMIT = (page: number, limit: number)=>{
  return `query {
    boards(limit: ${limit}, page: ${page}){
      board_folder_id
      board_kind
      description
      id
      items_count
      name
      permissions
      state
     
      type
      updated_at
      url
      workspace {
        id
        name
      }
    }
  }
`
};

export const GET_BOARD_ITEMS_QUERY = (boardId: string) => `
  query {
    boards (ids: [${boardId}]) {
      columns {
        id
        title
      }
      items_page (limit: 500) {
        items {
          id
          name
          column_values {
            column {
              title
            }
            id
            text
            value
          }
        }
      }
    }
  }
`;

export const GET_BOARD_NEXT_ITEMS_QUERY = (cursor?: string | null) => `
  query {
    next_items_page (cursor: "${cursor || ""}") {
      cursor
      items {
        id 
        name
        column_values {
          column {
            title
          }
          text
          id
          value
        }
      }
    }
  }
`;

/**
 * 
 * @param boardId 
 * @param limit max of 500
 * @returns 
 */
export const GET_BOARD_ITEMS_QUERY_BY_LIMIT = (boardId: string, limit: number, cursor: string | null | undefined) => `
  query {
    boards (ids: [${boardId}]) {
      columns {
        id
        title
      }
      items_page (limit: ${limit} ${cursor? `, cursor: "${cursor}"`: ""}) {
        cursor
        items {
          id
          name
          column_values {
            column {
              title
            }
            type
            id
            text
            value
            ...on MirrorValue {
              display_value
              type
              column {
                settings_str
                title
                type
              }
              mirrored_items {
                mirrored_value {
                  __typename
                }
              }
            }
            ... on BoardRelationValue {
              display_value
            }
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_DATA = `
query {
  me{
    id
    account{
      id
      name
    }
  }
}
`;

export const GET_USER_WORKSPACES = `
  query{
    workspaces{
      id
      name
    }
  }
`;

export const GET_ALL_USER_WORKSPACE_QUERY_WITH_LIMIT = (limit: number, page: number) => `
  query{
    workspaces(limit: ${limit}, page: ${page}){
      id
      name
    }
  }
`;

export const GET_ALL_BOARDS_QUERY_BY_WORKSPACE_AND_LIMIT = (
  workspaceId: number|string|null, 
  page: number, 
  limit: number
)=>{
  return `
    query {
      boards(workspace_ids: [${workspaceId}] ,limit: ${limit}, page: ${page}){
        board_folder_id
        board_kind
        description
        id
        items_count
        name
        permissions
        state
        type
        updated_at
        url
        workspace {
          id
          name
        }
      }
    }
  `
};

export const GET_BOARD_COLUMNS = (boardId: string) => {
  return `
    query {
      boards(ids: ${boardId}) {
        columns {
          id
          title
          type
          description
        }
      }
    }
  `
}