import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IoWarning } from "react-icons/io5";
import { Checkbox, Text } from "monday-ui-react-core";

import { CircledInfoIcon, DownloadIcon } from "../../../resources/icons";
import Button from "../../../components/Button";
import {
	useWorkspaceBoardsByAutoPagination,
} from "../../../hooks/monday/boardhooks";
import Logger from "../../../utilities/Logger";
import Dropdown, { IDropdownOption } from "../../../components/Dropdown";
import useWorkspaceByAutoPagination from "../../../hooks/monday/workspaceHooks";
import { exportBoardToSheetRequest } from "../../../requests/boardViewRequests";
import useUser from "../../../hooks/states/userHook";
import { getAllBoardItemsAndHeaders, getBoardColumns } from "../../../hooks/monday/boardItemsHooks";
import { Close } from "monday-ui-react-core/icons";
import clsx from "clsx";


interface IBoardSelectionProps {
	isSheetActive?: boolean;
	onCancel?: () => void;
	onImport: (headers: string[], rows: (string | number | boolean | Date)[][]) => Promise<void>;
	onLimitExceeded?: () => void;
}

function BoardSelection({ 
	isSheetActive, 
	onCancel, 
	onImport, 
	onLimitExceeded 
}: IBoardSelectionProps) {
	const { workspaces } = useWorkspaceByAutoPagination();
	const WorkSpaceOptions: IDropdownOption[] = workspaces.map((workspace) => ({
		label: workspace.name,
		value: workspace.id,
	}));
	const { userId } = useUser();
	const [workspaceID, setWorkspaceID] = useState<number | string | null>(0);
	const { boardOptions } = useWorkspaceBoardsByAutoPagination(workspaceID, true);
	const [selectedBoard, setSelectedBoard] = useState("");
	const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
	const [isSubmitting, setSubmitting] = useState(false);
	const [exportAllColumns, setExportAllColumns] = useState(true);
	const [columnOptions, setColumnOptions] = useState<IDropdownOption[]>([]);


	useEffect(() => {

		return () => {
			setSelectedBoard("");
			setSelectedColumns([]);
			setWorkspaceID(0)
		}
	}, [])


	const handleCloseModal = () =>{
		setSelectedBoard("");
		setWorkspaceID(0);
		setExportAllColumns(true);
		setSelectedColumns([]);

		onCancel ? onCancel(): null;
	}

	//this handles embedding selected board to the imported sheet
	const handleEmbedBoardToSheet = async () => {
		try {
			setSubmitting(true);

			if (selectedBoard) {
				const canExport = await exportBoardToSheetRequest(userId, selectedBoard);

				if(canExport) {
					const boardData = await getAllBoardItemsAndHeaders(selectedBoard, selectedColumns);
	
					if (boardData.items.length) {
						await onImport(
							boardData.headers,
							boardData.items
						);
						onCancel && onCancel();

					}
				} else {
					handleCloseModal();
					onLimitExceeded && onLimitExceeded();
				}
			}

		} catch (error) {
			Logger.error(error);
			
		} finally {
			setSubmitting(false);
		}
	};

	const onFetchColumns = async (boardId: string) => {
		setSelectedColumns([])
		const columns = await getBoardColumns(boardId);

		if(columns) {
			const options = columns.reduce<IDropdownOption[]>(
				(cum, cur) => [
					...cum, 
					{
						label: `${cur.title} (${cur.type})`,
						value: cur.id
					}
				], []
			);

			setColumnOptions(options);
		}
	}


	return (
		<div className="w-full px-6 space-y-4">
			<div className="space-y-4">
				<Text className="text-grayed font-normal text-2xl leading-[22px] inline-flex items-center gap-2">
					Select board to export
					<DownloadIcon />
				</Text>

				<Text className="text-grayed text-wrap">
					Please select the board to export to Google Sheet and load into the embed view
				</Text>
			</div>

			<div className="space-y-3 h-80">
				<div className="relative w-full">
					{/* Workspace dropdown */}
					<p>Workspace</p>
					<Dropdown
						placeholder="Select workspace"
						options={WorkSpaceOptions}
						searchable={true}
						value={WorkSpaceOptions.filter(e => e?.value == workspaceID)}
						className="min-w-[88px] z-12"
						onChange={(option) => {
							setWorkspaceID(option.value);
							setSelectedBoard("");
							setColumnOptions([])
							setSelectedColumns([])
						}}
					/>
					
					{/* Boards dropdown */}
					<p className="mt-3">Board</p>
					<Dropdown
						placeholder={workspaceID? "Select Board" : "Select a workspace first"}
						options={boardOptions}
						searchable={true}
						value={boardOptions.filter(e => e?.value == selectedBoard)}
						className="min-w-[88px] z-12 mb-3"
						onChange={(option) => {
							const boardId = option.value.toString();
							setSelectedBoard(boardId);
							// Fetch board columns
							onFetchColumns(boardId);
						}}
					/>

					{/* Columns */}
					<p className="mt-3">Columns</p>
					<div className="space-y-2 mb-2">
						<Checkbox
							checked={exportAllColumns}
							onChange={(e) => {
								const v = e.target.checked
								setExportAllColumns(v);

								// Set selected columns to empty list
								if(v) setSelectedColumns([])
							}}
							label="Export all columns"
						/>

						<Dropdown
							placeholder={
								exportAllColumns? "Export all columns"
									: (selectedBoard? "Select specific columns" : "Select a board first")
							}
							options={columnOptions.filter(
								(o) => o?.value && !selectedColumns.includes(o.value as string)
							)}
							searchable={true}
							value={columnOptions.filter(e => e?.value == selectedBoard)}
							className="min-w-[88px] z-12 mb-3"
							onChange={(option) => {
								setSelectedColumns([...selectedColumns, `${option.value}`])
								// setSelectedBoard(option.value.toString());
							}}
							disabled={exportAllColumns}
						/>
					</div>
					{/* Selected columns */}
					<div className="flex flex-wrap gap-1">
						{selectedColumns.map((column) => (
							<span 
								className={clsx(
									"p-[6px] text-[10px] rounded-full",
									"bg-primary/70 leading-none relative",
									"group"
								)}
							>
								{columnOptions.find((o) => (o.value as string) == column)?.label}
								<span 
									className={clsx(
										"rounded-full hover:shadow-md shadow-gray-200 cursor-pointer p-1 absolute",
										"top-0 right-0 hidden group-hover:block bg-red-700"
									)}
									onClick={() => {
										const list = _.remove(selectedColumns, (v) => v !== column);
										console.log(list)
										setSelectedColumns(list)
									}}
								>
									<Close className="w-3 h-3 text-white" />
								</span>
							</span>
						))}
					</div>
				</div>
				
				{/* Warning */}
				<div className="flex items-start gap-3 my-3">
					{isSheetActive ? (
						<>
							<CircledInfoIcon className="text-grayed h-8 w-8" />
							<Text className="text-grayed text-xs text-wrap mb-2">
								Exporting this board will share its data with a Google Sheet and display it in the
								embedded view, allowing seamless interaction with the data. <br />
							</Text>
						</>
					) : (
						<>
							<IoWarning className="h-8 w-8 text-red-500" />
							<Text className="text-xs text-wrap text-red-500">
								A sheet should be embedded or created to use this feature.
								<br />
								Feature requires an active sheet in preview
							</Text>
						</>
					)}
				</div>
			</div>

			{/* Footer */}
			<footer className="mt-5" style={{ marginTop: "50px" }}>
				<div className="w-full flex gap-x-4 items-center justify-between">
					<Button variant="outlined" onClick={handleCloseModal}>
						Cancel
					</Button>
					
					<Button
						loading={isSubmitting}
						disabled={isSubmitting || !isSheetActive}
						onClick={handleEmbedBoardToSheet}
					>
						Export to Sheet
					</Button>
				</div>
			</footer>
		</div>
	);
}

export default BoardSelection;
