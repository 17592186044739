import { AxiosError } from "axios";
import { gapi, loadClientAuth2 } from "gapi-script";

import { GC_SCOPES } from "../configs";
import Logger from "../utilities/Logger";
import { IFile, IFileParent } from "../resources/types";


export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GD_CLIENT_ID as string;

export const MIME_TYPES = {
  EXCEL_SHEET: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  SPREAD_SHEET: "application/vnd.google-apps.spreadsheet",
  FOLDER: "application/vnd.google-apps.folder"
}

class GoogleDriveService {

  async init() {
    try {
      await loadClientAuth2(gapi, GOOGLE_CLIENT_ID, GC_SCOPES);

    } catch (error: AxiosError | Error | any) {
      Logger.error(error);
    }
  }

  async connect(callback?: (token: GoogleApiOAuth2TokenObject) => void) {
    try {
      gapi.auth.authorize({
        client_id: GOOGLE_CLIENT_ID,
        authuser: -1,
        scope: GC_SCOPES,

      }, (token) => {
        callback && callback(token);
      });

    } catch (error: Error | any) {
      // Logger.error(error);
      throw error;
    }
  }

  async getParent(parentId: string, token?: string) {
    try {
      if(token) gapi.client.setToken({access_token: token});

      const response = await gapi.client.request({
        path: `https://www.googleapis.com/drive/v3/files/${parentId}?`,
      });
      
      if(response?.status && (response.status >= 200 && response.status <= 299)) {
        return response.result as IFileParent;
      }

      throw new Error("Unknown Error!")
      
    } catch (error: Error | any) {
      // Logger.error(error);
      throw error;
    }
  }

  async getFolderChildren(folderId: string, token?: string) {
    try {
      if(token) gapi.client.setToken({access_token: token});

      const searchParams = new URLSearchParams();
      searchParams.set(
        "fields", 
        [
          "nextPageToken",
          "kind",
          "incompleteSearch",
          "files(id, name, modifiedTime, createdTime, fileExtension, mimeType, parents, fullFileExtension)"
        ].join(",")
      );
      const params = searchParams.toString();      

      const response = await gapi.client.request({
        path: "https://www.googleapis.com/drive/v3/files?"+params,
        params: {
          includeItemsFromAllDrives: true,
          supportsAllDrives: true,
          orderBy: ["name"].join(","),
          includeLabels: true,
          q: `'${folderId}' in parents and (mimeType='${MIME_TYPES.SPREAD_SHEET}' or mimeType='${MIME_TYPES.FOLDER}')`,
          trashed: false,
        },
      });
      
      if(response?.status && (response.status >= 200 && response.status <= 299)) {
        return response.result?.files as Array<IFile> || [];
      }

      throw new Error("Unknown Error!")
      
    } catch (error: Error | any) {
      // Logger.error(error);
      throw error;
    }
  }

  async getSubFolders(folderId: string, token?: string) {
    try {
      if(token) gapi.client.setToken({access_token: token});

      const searchParams = new URLSearchParams();
      searchParams.set(
        "fields", 
        [
          "nextPageToken",
          "kind",
          "incompleteSearch",
          "files(id, name, modifiedTime, createdTime, fileExtension, mimeType, parents, fullFileExtension)"
        ].join(",")
      );
      const params = searchParams.toString();      

      const response = await gapi.client.request({
        path: "https://www.googleapis.com/drive/v3/files?"+params,
        params: {
          includeItemsFromAllDrives: true,
          supportsAllDrives: true,
          orderBy: ["name"].join(","),
          includeLabels: true,
          q: `'${folderId}' in parents and trashed=false and mimeType='application/vnd.google-apps.folder'`,
          trashed: false,
        },
      });
      
      if(response?.status && (response.status >= 200 && response.status <= 299)) {
        return response.result?.files as Array<IFile> || [];
      }

      throw new Error("Unknown Error!")
      
    } catch (error: Error | any) {
      // Logger.error(error);
      throw error;
    }
  }

  async listFolders(token?: string) {
    try {
      if(token) gapi.client?.setToken({access_token: token});

      const searchParams = new URLSearchParams();
      searchParams.set(
        "fields", 
        [
          "nextPageToken",
          "kind",
          "incompleteSearch",
          "files(id, name, modifiedTime, createdTime, mimeType, parents, shared, starred, spaces, trashed)"
        ].join(",")
      );
      const params = searchParams.toString();      

      const response = await gapi.client.request({
        path: "https://www.googleapis.com/drive/v3/files?"+params,
        params: {
          corpora: "user",
          includeItemsFromAllDrives: true,
          supportsAllDrives: true,
          orderBy: ["modifiedTime", "createdTime", "folder"].join(","),
          includeLabels: true,
          q: "('root' in parents) and trashed=false and mimeType='application/vnd.google-apps.folder'",
        },
      });
      
      if(response?.status && (response.status >= 200 && response.status <= 299)) {
        return response.result?.files as Array<IFile> || [];
      }

      throw new Error("Unknown Error!")
      
    } catch (error: Error | any) {
      // Logger.error(error);
      throw error;
    }
  }

  /**
   * 
   * @param token 
   * @returns all files and folders in root directory like My Drive
   */
  async listFiles(token?: string) {
    try {
      if(token) gapi.client.setToken({access_token: token});

      const searchParams = new URLSearchParams();
      searchParams.set(
        "fields", 
        [
          "nextPageToken",
          "kind",
          "incompleteSearch",
          "files(id, name, modifiedTime, createdTime, mimeType, parents, shared, starred, spaces, trashed)"
        ].join(",")
      );
      const params = searchParams.toString();      

      const response = await gapi.client.request({
        path: "https://www.googleapis.com/drive/v3/files?"+params,
        params: {
          corpora: "user",
          includeItemsFromAllDrives: true,
          supportsAllDrives: true,
          orderBy: ["modifiedTime desc", "createdTime", "folder"].join(","),
          includeLabels: true,
          trashed: false,
          q: [
            "('root' in parents or sharedWithMe=true)",
            "and trashed=false", 
            `and (
              mimeType='${MIME_TYPES.SPREAD_SHEET}' 
              or mimeType='${MIME_TYPES.EXCEL_SHEET}' 
              or mimeType='${MIME_TYPES.FOLDER}'
            )`
          ].join(" ")
        },
      });
      
      if(response?.status && (response.status >= 200 && response.status <= 299)) {
        return response.result?.files as Array<IFile> || [];
      }

      throw new Error("Unknown Error!");
      
    } catch (error: Error | any) {
      // Logger.error(error);
      throw error;
    }
  }

  // get file data by fileId
  async getFile(fileId: string, token?: string) {
    try {
      if(token) gapi.client.setToken({access_token: token});

      const response = await gapi.client.request({
        path: `https://www.googleapis.com/drive/v3/files/${fileId}/export`,
        params: {
          mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      });
      
      if(response?.status && (response.status >= 200 && response.status <= 299)) {
        new Blob([response.body])
      }

      throw new Error("Unknown Error!")
      
    } catch (error: Error | any) {
      // Logger.error(error);
      throw error;
    }
  }

  // update file location by fileId
  async updateFile(fileId: string, parentFolderId: string, token?: string) {
    try {
      if(token) gapi.client.setToken({access_token: token});

      const response = await gapi.client.request({
        path: `https://www.googleapis.com/drive/v3/files/${fileId}`,
        method: "PATCH",
        params: {
          addParents: parentFolderId,
          removeParents: 'root',
          fields: 'id, parents',
        },
      });
      
      if(response?.status && (response.status >= 200 && response.status <= 299)) {
        new Blob([response.body])
      }
      
    } catch (error: Error | any) {
      // Logger.error(error);
      throw error;
    }
  }
}

export default GoogleDriveService;