import React from "react";
import { Text } from "monday-ui-react-core";



type Props = {}

function ViewOnly() {
  return (
    <div className="flex items-center justify-center flex-col max-w-60 space-y-4 text-center">
      <Text className="text-4xl font-semibold text-red-500">Access Denied !!</Text>

      <div className="text-center">
        <p>
          Sorry, GSFI doesn&apos;t permit users or accounts with 
          <span> view only</span> or <span>guest</span> permissions.
        </p>
        <p className="text-grayed text-sm">
          Try upgrading your permissions with your account admin and get back to us.
        </p>
      </div>
    </div>
  )
}

export default ViewOnly