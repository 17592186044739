import mondaySdk from "monday-sdk-js";
import { AppFeatureAiBoardMainMenuHeaderContext, AppFeatureAiDocQuickStartType, AppFeatureAiDocSlashCommandContext, AppFeatureAiDocTopBarContext, AppFeatureBoardViewContext, AppFeatureDashboardWidgetContext, AppFeatureDocActionsContext, AppFeatureGroupMenuActionContext, AppFeatureItemBatchActionContext, AppFeatureItemMenuActionContext, AppFeatureItemViewContext, AppFeatureObjectContext, AppFeatureTypes, AppFeatureWorkspaceViewContext, BaseContext } from "monday-sdk-js/types/client-context.type";

import Logger from "../utilities/Logger";
import { MONDAY_CLIENT_ID } from "../configs";
import { IMondayQueryOptions } from "../resources/types/responses";
import { FilterResponse, LocationResponse } from "monday-sdk-js/types/client-data.interface";



class MondayService {
  private static monday  = mondaySdk();

  constructor() {
    MondayService.monday.setApiVersion("2024-04");
  }


  sendValueCreatedForUser() {
    MondayService.monday.execute("valueCreatedForUser");
  }

  openPlanSelection() {
    MondayService.monday.execute("openPlanSelection");
  }

  async getContext<T>() {
    try {
      const context = await MondayService.monday.get<BaseContext & T>("context");

      if(context.errorMessage) {
        throw new Error(context.errorMessage);
      }

      return context.data;
      
    } catch (error: Error | unknown) {
      Logger.error(error);
      throw error;
    }
  }

  async authorizeUser() {
    try {
      MondayService.monday.oauth({
        clientId: MONDAY_CLIENT_ID,
        mondayOauthUrl: "https://auth.monday.com/oauth2/authorize"
      });
      
    } catch (error) {
      Logger.error(error);
      throw error;
    }
  }

  async query<T>(qry: string, option?: IMondayQueryOptions) {
		try {
			const response = await MondayService.monday.api<T>(qry, {
				apiVersion: option?.apiVersion,
			});

			return response;
		} catch (error) {
			Logger.error(error);
			throw error;
		}
	}

  listen(
    event: "context" | "events" | "filter" | "itemIds" | "location" | "settings",
    callback: (res: { 
      data: BaseContext | AppFeatureBoardViewContext | AppFeatureAiBoardMainMenuHeaderContext 
      | LocationResponse | number[] | FilterResponse | AppFeatureDocActionsContext
      | AppFeatureAiDocSlashCommandContext | AppFeatureAiDocTopBarContext
      | AppFeatureAiDocQuickStartType | AppFeatureItemViewContext
      | AppFeatureWorkspaceViewContext | AppFeatureObjectContext
      | AppFeatureGroupMenuActionContext | AppFeatureItemBatchActionContext
      | AppFeatureItemMenuActionContext | AppFeatureDashboardWidgetContext
    }) => void,
    params?: Record<string, any> & { appFeatureType?: AppFeatureTypes }
  ) {
    try {
      MondayService.monday.listen(event, callback, params);
      
    } catch (error) {
      Logger.error("MONDAY ERROR: ", error);
      throw error;
    }
  }

}

export default MondayService;
