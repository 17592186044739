import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";



export interface IUserState {
  userId: string;
  accountId: string;
  boardId?: string;
  viewId?: number;
  appFeature?: string;
  workspaceId?: number;
  google?: {
    token: string;
    ref: string;
    refresh_token: string;
    id_token: string;
    email: string;
    profile?: string;
    name: {
      firstName: string;
      lastName: string;
      fullName: string;
    },
    isAcceptAllPermissions?: boolean
  };
}

const initialState: IUserState = {
  userId: "",
  accountId: "",
};
const googleDefaults = {
  email: "",
  name: {
    firstName: "",
    fullName: "",
    lastName: "",
  },
  ref: "",
  token: "",
  profile: "",
  refresh_token: "",
  id_token: "",
  isAcceptAllPermissions: false
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_USER_ACCOUNT: (state, action: PayloadAction<IUserState>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    UPDATE_USER_ACCOUNT: (state, action: PayloadAction<Partial<IUserState>>) => {
      state = {
        ...state,
        ...action.payload
      };
      return state;
    },
    UPDATE_GOOGLE: (state, action: PayloadAction<Partial<IUserState["google"]>>) =>{
      state = {
        ...state,
        google: {
          ...googleDefaults,
          ...state.google,
          ...action.payload
        }
      };
      return state;
    },
    SET_ACTIVE_BOARD: (
      state, 
      action: PayloadAction<Pick<IUserState, "boardId" | "viewId" | "workspaceId" | "appFeature">>
    ) => {
      state = {
        ...state,
        boardId: action.payload.boardId,
        viewId: action.payload.viewId,
        workspaceId: action.payload.workspaceId,
        appFeature: action.payload.appFeature
      }
      return state;
    },
    SIGN_GOOGLE_OUT: (state) => {
      return state = {
        ...state,
        google: undefined,
      }
    },
    SIGN_USER_OUT: (state) => state = initialState
  }
});

export const getUser = (state: RootState): IUserState => state.USER || initialState;


export const { 
  UPDATE_USER_ACCOUNT, 
  SIGN_USER_OUT, 
  SET_USER_ACCOUNT, 
  SIGN_GOOGLE_OUT,
  SET_ACTIVE_BOARD,
  UPDATE_GOOGLE
} = userSlice.actions;
export default userSlice.reducer;