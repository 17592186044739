import React, { useEffect } from "react";


declare global {
  interface Window {
    $crisp: Array<any>;
    CRISP_WEBSITE_ID?: string;
  }
}


const CRISP_WEB_ID = process.env.REACT_APP_CRISP_WEB_ID;

function useCrisp() {

  useEffect(() =>{
    // Reset crisp array
    window.$crisp = [];

    window.CRISP_WEBSITE_ID = CRISP_WEB_ID;

    const script = document.createElement("script");
    script.src="https://client.crisp.chat/l.js";
    script.async = true;
    document.head.appendChild(script);

    return () =>{
      document.head.removeChild(script);
    }
  }, []);

  
}

export default useCrisp;