const BASE_PATH = "/api";

// Authorizations
const AUTH_PATH = BASE_PATH + "/auth";
export const GOOGLE_AUTH = AUTH_PATH + "/google";
export const GOOGLE_AUTH_REFRESH_TOKEN = GOOGLE_AUTH + "/refresh";

export const USER_VERIFICATION = AUTH_PATH + "/user_verification";

const USER_PATH = BASE_PATH + "/user"
export const LINK_DOC = USER_PATH + "/board/link_sheet";
export const GET_VIEW_DATA = USER_PATH + "/board/get_board_view";
export const LINK_DOC_TO_OBJECT = USER_PATH + "/board/link_custom_object";
export const GET_OBJECT_VIEW_DATA = USER_PATH + "/board/get_custom_object";
export const EXPORT_BOARD_TO_SHEET = USER_PATH + "/board/export_board";