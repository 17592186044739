import React, { useEffect, useRef, useState } from "react";
import {
	Dialog,
	DialogContentContainer,
	ExpandCollapse,
	Loader,
	Skeleton,
	Text,
	TextField,
} from "monday-ui-react-core";
import clsx from "clsx";
import _ from "lodash";

import { SheetIcon } from "../../../resources/icons";
import Button from "../../../components/Button";
import Logger from "../../../utilities/Logger";
import { useDriveFolders } from "../../../hooks/services/googleHook";
import { IFile } from "../../../resources/types";
import useUser from "../../../hooks/states/userHook";
import GoogleDriveService from "../../../services/GoogleDriveService";
import { truncatePath } from "../../../utilities";

type IFolderSelection = {
	parentFolder: IFile;
	selectedFolder?: IFile;
	defaultSelectedFolderId?: string;
	onSelectFolder: (folder: IFile) => void;
};

function FolderSelection({
	parentFolder,
	selectedFolder,
	defaultSelectedFolderId,
	onSelectFolder,
}: IFolderSelection) {
	const { driveToken } = useUser();
	const googleDriveService = new GoogleDriveService();
	const [isLoading, setLoading] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [folders, setFolders] = useState<Array<IFile>>([]);

	const fetchSubFolder = async () => {
		try {
			setLoading(true);
			const response = await googleDriveService.getSubFolders(parentFolder.id, driveToken);
			const files = response.map((f) => ({
				...f,
				path: [...(parentFolder?.path ?? []), f.name],
			}));

			// Sets sub folder as default
			const foundDefault = files.find((f) => f.id == defaultSelectedFolderId);
			if (foundDefault) onSelectFolder(foundDefault);

			setFolders(files);
		} catch (error) {
			Logger.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchSubFolder();
	}, []);

	return (
		<ExpandCollapse
			title={
				<div className="col-span-8 flex items-center gap-x-3">
					<Text className="text-sm">{parentFolder.name}</Text>
				</div>
			}
			headerComponentRenderer={() => <div>Something</div>}
			open={isOpen}
			hideBorder
			headerClassName="py-1 px-4"
			contentClassName="py-1"
			onClick={() => {
				onSelectFolder(parentFolder);
				setOpen(!isOpen);
			}}
		>
			{isLoading ? (
				<Skeleton
					type={Skeleton.types.TEXT}
					size={Skeleton.sizes.TEXT.H2}
					className="my-2 w-full"
					width={493}
				/>
			) : (
				<>
					{folders.map((folder) => (
						<FolderSelection
							key={folder.id}
							parentFolder={folder}
							defaultSelectedFolderId={""}
							onSelectFolder={onSelectFolder}
							selectedFolder={selectedFolder}
						/>
					))}
				</>
			)}
		</ExpandCollapse>
	);
}

type INewSheetProps = {
	onCancel?: () => void;
	onCreateSpreadsheet: (title: string, file?: IFile) => Promise<void>;
	folder?: string;
	path?: string[];
};

function NewSheet({ folder, onCancel, onCreateSpreadsheet }: INewSheetProps) {
	const { folderData, isLoading } = useDriveFolders();
	const [sheetName, setSheetName] = useState("");
	const [selectedFolder, setSelectedFolder] = useState<IFile>();
	const [isSubmitting, setSubmitting] = useState(false);
	const [rootAccordionOpen, setRootAccordionOpen] = useState(false);
	const pathName = selectedFolder ? selectedFolder.path?.join(" > ") : "My Drive";
	const FoldersWithPath = folderData.map((f) => ({
		...f,
		path: ["My Drive", f.name],
	}));
	const divRef = useRef(null);


	const handleSubmit = async () => {
		try {
			setSubmitting(true);

			await onCreateSpreadsheet(sheetName, selectedFolder);
			onCancel && onCancel();

		} catch (error) {
			Logger.error(error);

		} finally {
			setSubmitting(false);
		}
	};

	const toggleDialog = () => {
		if (divRef.current) {
			// @ts-ignore
			divRef.current?.click();
		}
	};

	useEffect(() => {
		if (folder && folderData.length > 0) {
			const found = FoldersWithPath.find((f) => f.id == folder);

			if (found) setSelectedFolder(found);
		}
	}, [folder, folderData]);


	
	return (
		<div className="w-full space-y-4">
			
			{isSubmitting ? (
				<div className="w-full flex justify-center items-center h-full my-12">
					<Loader size={Loader.sizes.MEDIUM} />
				</div>
			) : (
				<>
					<div className="space-y-4">
						<Text className="text-grayed font-normal text-2xl leading-[22px] inline-flex items-center gap-2">
							Create new spreadsheet
							<SheetIcon />
						</Text>

						<Text className="text-grayed text-wrap" ref={divRef} onClick={toggleDialog}>
							Provide the title of the sheet
						</Text>
					</div>

					<div className="space-y-4">
						<div className="relative w-full">
							<Dialog
								hideTrigger={[Dialog.hideShowTriggers.CLICK, Dialog.hideShowTriggers.CLICK_OUTSIDE]}
								hideWhenReferenceHidden
								showTrigger={Dialog.hideShowTriggers.CLICK}
								position={Dialog.positions.BOTTOM_START}
								content={
									<DialogContentContainer
										style={{
											width: "355px",
											maxHeight: "230px",
											overflowY: "auto",
										}}
									>
										<div className="w-full max-h-[355px]">
											{isLoading ? (
												<div className="w-full flex justify-center items-center h-full">
													<Loader size={Loader.sizes.SMALL} />
												</div>
											) : (
												<ExpandCollapse
													title={
														<div className="col-span-8 flex items-center gap-x-3">
															<Text className="text-sm">My Drive</Text>
														</div>
													}
													open={rootAccordionOpen}
													hideBorder
													headerClassName="py-1 px-4"
													contentClassName="py-1"
													onClick={() => {
														setSelectedFolder(undefined);
														setRootAccordionOpen(!rootAccordionOpen);
													}}
												>
													{FoldersWithPath.map((folder) => (
														<FolderSelection
															key={folder.id}
															parentFolder={folder}
															onSelectFolder={setSelectedFolder}
															selectedFolder={selectedFolder}
															defaultSelectedFolderId={""}
														/>
													))}
												</ExpandCollapse>
											)}
										</div>
										<div className="w-full flex gap-x-4 items-center">
											<Button onClick={toggleDialog}>Select</Button>
										</div>
									</DialogContentContainer>
								}
								zIndex={10000}
							>
								<button
									className={clsx(
										"border border-solid border-[#e5e7eb] bg-white rounded",
										"relative w-full px-4 py-2 h-10",
										"text-ellipsis text-gray-500 font-light text-start",
										"hover:border-[#323338] focus:border-[#323338] cursor-pointer"
									)}
								>
									{truncatePath(pathName ?? "", 40) || "Select path"}
								</button>
							</Dialog>
						</div>

						<TextField
							placeholder="Spreadsheet name"
							size="medium"
							autoFocus
							onChange={(v) => setSheetName(v)}
							value={sheetName}
						/>
					</div>

					<footer className="!mt-10">
						<div className="w-full flex gap-x-4 items-center">
							<Button variant="outlined" onClick={onCancel}>
								Cancel
							</Button>
							<Button disabled={isSubmitting || sheetName.length < 1} onClick={handleSubmit}>
								{isSubmitting && <Loader size={Loader.sizes.XS} />}
								Create sheet
							</Button>
						</div>
					</footer>
				</>
			)}
		</div>
	);
}

export default NewSheet;
