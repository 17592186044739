import { GoogleSpreadsheet,  } from "google-spreadsheet";
import _ from "lodash";

import Logger from "../utilities/Logger";
import { IGoogleSheetData } from "../resources/types";
import { AxiosError } from "axios";



class GoogleSheetService {
  
  async getSheetData(fileId: string, token: string): Promise<{doc: GoogleSpreadsheet}> {
    try {
      const doc = new GoogleSpreadsheet(fileId, {token});
      await doc.loadInfo(true);
    
      return {doc};
      
    } catch (error: AxiosError | Error | any) {
      if((error as AxiosError)?.status == 401) {
        throw error;

      } else {
        Logger.error(error);
        throw error;
      }
    }
  }

  async createSheetData(fileName: string, token: string): Promise<GoogleSpreadsheet> {
    try {
      const doc = await GoogleSpreadsheet.createNewSpreadsheetDocument({token}, { 
        title: fileName,
      });
      
      return doc;

    } catch (error: AxiosError | Error | any) {
      if((error as AxiosError)?.status == 401) {
        throw error;
        
      } else {
        Logger.error(error);
        throw error;
      }
    }
  }
}

export default GoogleSheetService;