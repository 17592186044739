import React from "react";
import {
  Toggle as MondayToggle
} from "monday-ui-react-core";



type IToggleProps = {
  className?: string;
}

function Toggle({
  className
}: IToggleProps) {
  return (
    <MondayToggle  
      offOverrideText=""
      onOverrideText=""
      isDefaultSelected={false}
      disabled
    />
  )
}

export default Toggle