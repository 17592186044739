import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import useUser from "../hooks/states/userHook";
import * as paths from "../routes/paths";
import { GC_CLIENT_ID } from "../configs";


function UserLayout() {
  const {isAuthorized} = useUser();
  const navigate = useNavigate();


  useEffect(() => {
    if(!isAuthorized) {
      navigate(paths.home, {replace: true});
    } else {
      navigate(paths.upload)
    }

  }, [isAuthorized]);


  return (
    <div className="w-dvw h-dvh bg-white">
      <GoogleOAuthProvider clientId={GC_CLIENT_ID}>
        <Outlet />
      </GoogleOAuthProvider>
    </div>
  )
}

export default UserLayout;
