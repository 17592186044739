import React, { ReactElement } from "react";
import { ModalContent, Modal as MondayModal } from "monday-ui-react-core";
import { ModalWidth } from "monday-ui-react-core/dist/types/components/Modal/ModalHelper";
import clsx from "clsx";


type IModalProps = {
  title?: string;
  show?: boolean;
  sticky?: boolean;
  showCloseButton?: boolean;
  children: ReactElement | string;
  onClose?: () => void;
  width?: string | typeof ModalWidth;
  containerClassName?: string;
  overlayClassName?: string;
  modalClassName?: string;
  description?: string;
  backdrop?: boolean;
}

function Modal({
  title,
  show = false,
  sticky = false,
  showCloseButton = true,
  children,
  onClose,
  width,
  containerClassName,
  modalClassName,
  overlayClassName, 
  description,
  backdrop = true
}: IModalProps) {

  return (
    <MondayModal
      contentSpacing
      title={title} 
      show={show}
      alertDialog={sticky}
      width={width}
      onClose={()=> {
        onClose && onClose();
      }}
      classNames={{
        container: clsx(
          containerClassName
        ),
        modal: clsx(
          "p-3 pt-5", 
          {"monday-modal-hide-close-btn": !showCloseButton},
          modalClassName
        ),
        overlay: clsx(
          {"bg-transparent": !backdrop},
          overlayClassName
        )
      }}
      description={description}
      unmountOnClose={false}
    >
      <ModalContent>
        {children}
      </ModalContent>
  </MondayModal>
  )
}

export default Modal