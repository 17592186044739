import React, { useState, useRef, useEffect, useCallback } from "react";

const Carousel = () => {
  const [index, setIndex] = useState(0);
  const carouselRef = useRef(null);

  const updateCarousel = useCallback(() => {
    //@ts-expect-error err
    const width = carouselRef.current.clientWidth;
    //@ts-expect-error err
    carouselRef.current.style.transform = `translateX(-${index * width}px)`;
  }, [index]);

  useEffect(() => {
    updateCarousel();
    window.addEventListener("resize", updateCarousel);
    return () => window.removeEventListener("resize", updateCarousel);
  }, [index, updateCarousel]);

  const nextSlide = () => {
    setIndex((prevIndex) => (prevIndex + 1) % 2);
  };

  const prevSlide = () => {
    setIndex((prevIndex) => (prevIndex - 1 + 2) % 2);
  };

  const goToSlide = (index: number) => {
    setIndex(() => (index + 2) % 2);
  };

  return (
    <div className="relative overflow-hidden max-w-7xl mx-auto">
       
        <div
          ref={carouselRef}
          className="flex transition-transform duration-500 mb-5"
        >
          <div className="w-full flex-shrink-0 px-6 md:px-16">
            <img src="../../../recepies.gif" className="w-full" alt="" /> <br />
          </div>
          <div className="w-full flex-shrink-0 px-6 md:px-16">
          <img src="../../../custom_automation.gif" className="w-full" alt="" />
          </div>
          
        </div>
        <button
          onClick={prevSlide}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white p-2"
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-300/30 group-hover:bg-white/50">
            <svg
              className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white p-2"
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-300/30 group-hover:bg-white/50">
            <svg
              className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
        <div className="absolute bottom-1 left-0 right-0 flex justify-center pt-10 px-8 mt-8">
          {[0, 1].map((_, i) => (
            <button
              key={i}
              className={`mx-1 w-3 h-3 rounded-full ${
                i === index ? "bg-green-700" : "bg-gray-400"
              }`}
              onClick={() => goToSlide(i)}
            ></button>
          ))}
        </div>
      </div>
  );
};

export default Carousel;
