import React, { KeyboardEvent, MouseEvent } from "react";
import {
  Avatar as MondayAvatar
} from "monday-ui-react-core";
import { AvatarSize } from "monday-ui-react-core/dist/types/components/Avatar/AvatarConstants";
import clsx from "clsx";
import { UserIcon } from "../resources/icons";
import { IconType } from "react-icons";



interface IAvatarProps {
  src?: string;
  size?: AvatarSize;
  label?: string;
  className?: string;
  disabled?: boolean;
  rounded?: boolean;
  bordered?: boolean;
  initials?: string;
  showTooltip?: boolean;
  icon?: IconType;
  onClick?: () => void;
}

function Avatar({
  src,
  size = MondayAvatar.sizes.LARGE,
  label,
  className,
  disabled,
  rounded = true,
  initials,
  bordered = true,
  showTooltip,
  icon,
  onClick
}: IAvatarProps) {
  const innerType = src? MondayAvatar.types.IMG : (initials? MondayAvatar.types.TEXT : MondayAvatar.types.ICON);
  

  return (
    <MondayAvatar
      size={size}
      type={innerType}
      src={src}
      ariaLabel={label}
      customBackgroundColor="#258750"
      className={clsx(
      //   // "border-spacing-3 border-solid border",
        className
      )}
      disabled={disabled}
      square={!rounded}
      textClassName="text-white"
      text={initials}
      withoutBorder={!bordered}
      withoutTooltip={!showTooltip}
      icon={icon}
      onClick={onClick}
    />
  )
}

export default Avatar;

