export enum BROWSER_OS {
  WINDOWS = "Win",
  MAC_OS = "Mac",
  UNIX = "X11",
  LINUX = "Linux",
}

export enum ClipboardActions {
  copy = "copy",
  cut = "cut",
}