import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { Toast } from "monday-ui-react-core";
import { IconType } from "react-icons";
import clsx from "clsx";



type IToastProps = {
  content?: JSX.Element | ReactElement | string;
  actionButton?: {
    title: string;
    onClick?: () => void;
    },
  closeable?: boolean;
  duration?: number;
  onCloseCallback?: () => void;
  showIcon?: boolean;
  isLoading?: boolean;
  icon?: string | IconType;
  className?: string;
  type?: "error" | "success" | "warning" | "info"
  position?: {
    vertical?: "top" | "middle" | "bottom",
    horizontal?: "left" | "center" | "right"
  }
}

function useToast() {
  const [open, setOpen] = useState(false);
  const [toastState, setToastState] = useState<IToastProps>({
    duration: 3400,
    closeable: true,
    showIcon: true,
    type: "error",
    position: {
      horizontal: "right",
      vertical: "top"
    }
  });


  const showToast = (props?: IToastProps) => {
    setOpen(true);

    setToastState({
      ...toastState,
      ...props
    })
  }

  const closeToast = useCallback((callback?: () => void) => {    
    setOpen(false); 
    setToastState({});

    if(callback && typeof callback === "function")
      callback();       
  }, []);



  const ToastComponent = () => (
    <Toast
      actions={toastState?.actionButton? [
        {
          type: Toast.actionTypes.BUTTON,
          text: toastState.actionButton.title,
          content: toastState.actionButton.title,
          ...toastState.actionButton
        }
      ] : []}
      autoHideDuration={toastState?.duration}
      closeable={toastState?.closeable}
      onClose={() => closeToast(toastState?.onCloseCallback)}
      hideIcon={!toastState?.showIcon}
      icon={toastState?.icon}
      loading={toastState?.isLoading}
      className={clsx(
        "!w-fit mr-0 min-w-[300px]",
        {"left-5" : toastState.position?.horizontal == "left"},
        {"right-5" : toastState.position?.horizontal == "right"},
        {"right-1/2 translate-x-1/2" : toastState.position?.horizontal == "center"},
        // "transition-all translate-x-[calc(100%_+_20px)]",
        // {"translate-x-0 duration-150 delay-75": open},
        toastState?.className
      )}
      open={open}
      type={
        toastState.type == "error"? Toast.types.NEGATIVE : 
          (toastState.type == "info"? Toast.types.NORMAL : 
            (toastState.type == "warning"? Toast.types.WARNING :  Toast.types.POSITIVE )
          )
      }

    >
      {toastState.content}
    </Toast>
  )

  const MemoizedToastComponent = useMemo(ToastComponent, [toastState, open, closeToast]);

  
  return {
    MemoizedToastComponent,
    ToastComponent,
    showToast,
    closeToast
  }
}

export default useToast;